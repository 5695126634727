import { render, staticRenderFns } from "./Links.vue?vue&type=template&id=92d7e4d4&scoped=true&"
import script from "./Links.vue?vue&type=script&lang=js&"
export * from "./Links.vue?vue&type=script&lang=js&"
import style0 from "./Links.vue?vue&type=style&index=0&id=92d7e4d4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92d7e4d4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SearchResults: require('C:/home/site/wwwroot/components/search/Results.vue').default})
