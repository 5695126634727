export const BlogHowToGetStarted = () => import('../..\\components\\blog\\HowToGetStarted.vue' /* webpackChunkName: "components/blog-how-to-get-started" */).then(c => wrapFunctional(c.default || c))
export const BlogPreviewItem = () => import('../..\\components\\blog\\PreviewItem.vue' /* webpackChunkName: "components/blog-preview-item" */).then(c => wrapFunctional(c.default || c))
export const ClientLoginCourseInformation = () => import('../..\\components\\clientLogin\\CourseInformation.vue' /* webpackChunkName: "components/client-login-course-information" */).then(c => wrapFunctional(c.default || c))
export const ClientLoginHeader = () => import('../..\\components\\clientLogin\\Header.vue' /* webpackChunkName: "components/client-login-header" */).then(c => wrapFunctional(c.default || c))
export const ConfirmationContactForm = () => import('../..\\components\\confirmation\\ContactForm.vue' /* webpackChunkName: "components/confirmation-contact-form" */).then(c => wrapFunctional(c.default || c))
export const CoursesCard = () => import('../..\\components\\courses\\Card.vue' /* webpackChunkName: "components/courses-card" */).then(c => wrapFunctional(c.default || c))
export const CoursesChooseYourCategory = () => import('../..\\components\\courses\\ChooseYourCategory.vue' /* webpackChunkName: "components/courses-choose-your-category" */).then(c => wrapFunctional(c.default || c))
export const CoursesChooseYourCategoryFr = () => import('../..\\components\\courses\\ChooseYourCategoryFr.vue' /* webpackChunkName: "components/courses-choose-your-category-fr" */).then(c => wrapFunctional(c.default || c))
export const CoursesFAQ = () => import('../..\\components\\courses\\FAQ.vue' /* webpackChunkName: "components/courses-f-a-q" */).then(c => wrapFunctional(c.default || c))
export const CoursesFrenchCard = () => import('../..\\components\\courses\\FrenchCard.vue' /* webpackChunkName: "components/courses-french-card" */).then(c => wrapFunctional(c.default || c))
export const CoursesGraphicsCard = () => import('../..\\components\\courses\\GraphicsCard.vue' /* webpackChunkName: "components/courses-graphics-card" */).then(c => wrapFunctional(c.default || c))
export const CoursesGraphicsCardFr = () => import('../..\\components\\courses\\GraphicsCardFr.vue' /* webpackChunkName: "components/courses-graphics-card-fr" */).then(c => wrapFunctional(c.default || c))
export const CoursesLandingPageHeaderLight = () => import('../..\\components\\courses\\LandingPageHeaderLight.vue' /* webpackChunkName: "components/courses-landing-page-header-light" */).then(c => wrapFunctional(c.default || c))
export const CoursesLandingPageHeaderLightFR = () => import('../..\\components\\courses\\LandingPageHeaderLightFR.vue' /* webpackChunkName: "components/courses-landing-page-header-light-f-r" */).then(c => wrapFunctional(c.default || c))
export const CoursesOutlinePageHeader = () => import('../..\\components\\courses\\OutlinePageHeader.vue' /* webpackChunkName: "components/courses-outline-page-header" */).then(c => wrapFunctional(c.default || c))
export const CoursesPrivateOrCustomizedTrainingForm = () => import('../..\\components\\courses\\PrivateOrCustomizedTrainingForm.vue' /* webpackChunkName: "components/courses-private-or-customized-training-form" */).then(c => wrapFunctional(c.default || c))
export const CoursesPrivateOrCustomizedTrainingFormFr = () => import('../..\\components\\courses\\PrivateOrCustomizedTrainingFormFr.vue' /* webpackChunkName: "components/courses-private-or-customized-training-form-fr" */).then(c => wrapFunctional(c.default || c))
export const CoursesRegistrationCard = () => import('../..\\components\\courses\\RegistrationCard.vue' /* webpackChunkName: "components/courses-registration-card" */).then(c => wrapFunctional(c.default || c))
export const CoursesRegistrationCardFr = () => import('../..\\components\\courses\\RegistrationCardFr.vue' /* webpackChunkName: "components/courses-registration-card-fr" */).then(c => wrapFunctional(c.default || c))
export const CoursesVideoCarousel = () => import('../..\\components\\courses\\VideoCarousel.vue' /* webpackChunkName: "components/courses-video-carousel" */).then(c => wrapFunctional(c.default || c))
export const CoursesWhatWouldYou = () => import('../..\\components\\courses\\WhatWouldYou.vue' /* webpackChunkName: "components/courses-what-would-you" */).then(c => wrapFunctional(c.default || c))
export const HomepageFooter = () => import('../..\\components\\homepage\\Footer.vue' /* webpackChunkName: "components/homepage-footer" */).then(c => wrapFunctional(c.default || c))
export const HomepageFrenchFooter = () => import('../..\\components\\homepage\\FrenchFooter.vue' /* webpackChunkName: "components/homepage-french-footer" */).then(c => wrapFunctional(c.default || c))
export const HomepageFrenchHeader = () => import('../..\\components\\homepage\\FrenchHeader.vue' /* webpackChunkName: "components/homepage-french-header" */).then(c => wrapFunctional(c.default || c))
export const HomepageHeader = () => import('../..\\components\\homepage\\Header.vue' /* webpackChunkName: "components/homepage-header" */).then(c => wrapFunctional(c.default || c))
export const HomepageHero = () => import('../..\\components\\homepage\\Hero.vue' /* webpackChunkName: "components/homepage-hero" */).then(c => wrapFunctional(c.default || c))
export const HomepageHeroFrench = () => import('../..\\components\\homepage\\HeroFrench.vue' /* webpackChunkName: "components/homepage-hero-french" */).then(c => wrapFunctional(c.default || c))
export const HomepageHeroUS = () => import('../..\\components\\homepage\\HeroUS.vue' /* webpackChunkName: "components/homepage-hero-u-s" */).then(c => wrapFunctional(c.default || c))
export const HomepageImageHeader = () => import('../..\\components\\homepage\\ImageHeader.vue' /* webpackChunkName: "components/homepage-image-header" */).then(c => wrapFunctional(c.default || c))
export const HomepageOurClients = () => import('../..\\components\\homepage\\OurClients.vue' /* webpackChunkName: "components/homepage-our-clients" */).then(c => wrapFunctional(c.default || c))
export const HomepageOurClientsFrench = () => import('../..\\components\\homepage\\OurClientsFrench.vue' /* webpackChunkName: "components/homepage-our-clients-french" */).then(c => wrapFunctional(c.default || c))
export const HomepageOurClientsUS = () => import('../..\\components\\homepage\\OurClientsUS.vue' /* webpackChunkName: "components/homepage-our-clients-u-s" */).then(c => wrapFunctional(c.default || c))
export const HomepageOurPromise = () => import('../..\\components\\homepage\\OurPromise.vue' /* webpackChunkName: "components/homepage-our-promise" */).then(c => wrapFunctional(c.default || c))
export const HomepageOurPromiseFrench = () => import('../..\\components\\homepage\\OurPromiseFrench.vue' /* webpackChunkName: "components/homepage-our-promise-french" */).then(c => wrapFunctional(c.default || c))
export const HomepageOurPromiseToYou = () => import('../..\\components\\homepage\\OurPromiseToYou.vue' /* webpackChunkName: "components/homepage-our-promise-to-you" */).then(c => wrapFunctional(c.default || c))
export const HomepageOurPromiseToYouFrench = () => import('../..\\components\\homepage\\OurPromiseToYouFrench.vue' /* webpackChunkName: "components/homepage-our-promise-to-you-french" */).then(c => wrapFunctional(c.default || c))
export const HomepageOurPromiseUS = () => import('../..\\components\\homepage\\OurPromiseUS.vue' /* webpackChunkName: "components/homepage-our-promise-u-s" */).then(c => wrapFunctional(c.default || c))
export const HomepageServices = () => import('../..\\components\\homepage\\Services.vue' /* webpackChunkName: "components/homepage-services" */).then(c => wrapFunctional(c.default || c))
export const HomepageServicesFrench = () => import('../..\\components\\homepage\\ServicesFrench.vue' /* webpackChunkName: "components/homepage-services-french" */).then(c => wrapFunctional(c.default || c))
export const HomepageWhatWantToLearn = () => import('../..\\components\\homepage\\WhatWantToLearn.vue' /* webpackChunkName: "components/homepage-what-want-to-learn" */).then(c => wrapFunctional(c.default || c))
export const HomepageWhatWantToLearnFrench = () => import('../..\\components\\homepage\\WhatWantToLearnFrench.vue' /* webpackChunkName: "components/homepage-what-want-to-learn-french" */).then(c => wrapFunctional(c.default || c))
export const HomepageWhatWantToLearnUS = () => import('../..\\components\\homepage\\WhatWantToLearnUS.vue' /* webpackChunkName: "components/homepage-what-want-to-learn-u-s" */).then(c => wrapFunctional(c.default || c))
export const NavigationBackTop = () => import('../..\\components\\navigation\\BackTop.vue' /* webpackChunkName: "components/navigation-back-top" */).then(c => wrapFunctional(c.default || c))
export const NavigationFrenchLinks = () => import('../..\\components\\navigation\\FrenchLinks.vue' /* webpackChunkName: "components/navigation-french-links" */).then(c => wrapFunctional(c.default || c))
export const NavigationLinks = () => import('../..\\components\\navigation\\Links.vue' /* webpackChunkName: "components/navigation-links" */).then(c => wrapFunctional(c.default || c))
export const PodcastEpisodes = () => import('../..\\components\\podcast\\Episodes.vue' /* webpackChunkName: "components/podcast-episodes" */).then(c => wrapFunctional(c.default || c))
export const SearchResults = () => import('../..\\components\\search\\Results.vue' /* webpackChunkName: "components/search-results" */).then(c => wrapFunctional(c.default || c))
export const SearchResultsFR = () => import('../..\\components\\search\\ResultsFR.vue' /* webpackChunkName: "components/search-results-f-r" */).then(c => wrapFunctional(c.default || c))
export const UIBaseCard = () => import('../..\\components\\UI\\BaseCard.vue' /* webpackChunkName: "components/u-i-base-card" */).then(c => wrapFunctional(c.default || c))
export const UIBaseCardContent = () => import('../..\\components\\UI\\BaseCardContent.vue' /* webpackChunkName: "components/u-i-base-card-content" */).then(c => wrapFunctional(c.default || c))
export const UIBaseHeader = () => import('../..\\components\\UI\\BaseHeader.vue' /* webpackChunkName: "components/u-i-base-header" */).then(c => wrapFunctional(c.default || c))
export const UIBaseHeaderBlog = () => import('../..\\components\\UI\\BaseHeaderBlog.vue' /* webpackChunkName: "components/u-i-base-header-blog" */).then(c => wrapFunctional(c.default || c))
export const UIBlueForm = () => import('../..\\components\\UI\\BlueForm.vue' /* webpackChunkName: "components/u-i-blue-form" */).then(c => wrapFunctional(c.default || c))
export const UIBlueFormFrench = () => import('../..\\components\\UI\\BlueFormFrench.vue' /* webpackChunkName: "components/u-i-blue-form-french" */).then(c => wrapFunctional(c.default || c))
export const UIFreeResources = () => import('../..\\components\\UI\\FreeResources.vue' /* webpackChunkName: "components/u-i-free-resources" */).then(c => wrapFunctional(c.default || c))
export const UIFreeResourcesCarousel = () => import('../..\\components\\UI\\FreeResourcesCarousel.vue' /* webpackChunkName: "components/u-i-free-resources-carousel" */).then(c => wrapFunctional(c.default || c))
export const UIFreeResourcesImages = () => import('../..\\components\\UI\\FreeResourcesImages.vue' /* webpackChunkName: "components/u-i-free-resources-images" */).then(c => wrapFunctional(c.default || c))
export const UIGrayContactForm = () => import('../..\\components\\UI\\GrayContactForm.vue' /* webpackChunkName: "components/u-i-gray-contact-form" */).then(c => wrapFunctional(c.default || c))
export const UIGrayContactFormFr = () => import('../..\\components\\UI\\GrayContactFormFr.vue' /* webpackChunkName: "components/u-i-gray-contact-form-fr" */).then(c => wrapFunctional(c.default || c))
export const UIGrayFormCompany = () => import('../..\\components\\UI\\GrayFormCompany.vue' /* webpackChunkName: "components/u-i-gray-form-company" */).then(c => wrapFunctional(c.default || c))
export const UIGrayFormFrench = () => import('../..\\components\\UI\\GrayFormFrench.vue' /* webpackChunkName: "components/u-i-gray-form-french" */).then(c => wrapFunctional(c.default || c))
export const UIGrayTrainingStrategyForm = () => import('../..\\components\\UI\\GrayTrainingStrategyForm.vue' /* webpackChunkName: "components/u-i-gray-training-strategy-form" */).then(c => wrapFunctional(c.default || c))
export const UIGrayWebinarForm = () => import('../..\\components\\UI\\GrayWebinarForm.vue' /* webpackChunkName: "components/u-i-gray-webinar-form" */).then(c => wrapFunctional(c.default || c))
export const UINewsletter = () => import('../..\\components\\UI\\Newsletter.vue' /* webpackChunkName: "components/u-i-newsletter" */).then(c => wrapFunctional(c.default || c))
export const UINewsletterFr = () => import('../..\\components\\UI\\NewsletterFr.vue' /* webpackChunkName: "components/u-i-newsletter-fr" */).then(c => wrapFunctional(c.default || c))
export const UINoRegistrationAvailableMessage = () => import('../..\\components\\UI\\NoRegistrationAvailableMessage.vue' /* webpackChunkName: "components/u-i-no-registration-available-message" */).then(c => wrapFunctional(c.default || c))
export const UIPackagesCard = () => import('../..\\components\\UI\\PackagesCard.vue' /* webpackChunkName: "components/u-i-packages-card" */).then(c => wrapFunctional(c.default || c))
export const UIPageHeader = () => import('../..\\components\\UI\\PageHeader.vue' /* webpackChunkName: "components/u-i-page-header" */).then(c => wrapFunctional(c.default || c))
export const UISnackBar = () => import('../..\\components\\UI\\SnackBar.vue' /* webpackChunkName: "components/u-i-snack-bar" */).then(c => wrapFunctional(c.default || c))
export const UISpinnerLarge = () => import('../..\\components\\UI\\SpinnerLarge.vue' /* webpackChunkName: "components/u-i-spinner-large" */).then(c => wrapFunctional(c.default || c))
export const UISpinnerLargeBlueBackground = () => import('../..\\components\\UI\\SpinnerLargeBlueBackground.vue' /* webpackChunkName: "components/u-i-spinner-large-blue-background" */).then(c => wrapFunctional(c.default || c))
export const UISpinnerLargeLogin = () => import('../..\\components\\UI\\SpinnerLargeLogin.vue' /* webpackChunkName: "components/u-i-spinner-large-login" */).then(c => wrapFunctional(c.default || c))
export const UISpinnerSmall = () => import('../..\\components\\UI\\SpinnerSmall.vue' /* webpackChunkName: "components/u-i-spinner-small" */).then(c => wrapFunctional(c.default || c))
export const UITestimonials = () => import('../..\\components\\UI\\Testimonials.vue' /* webpackChunkName: "components/u-i-testimonials" */).then(c => wrapFunctional(c.default || c))
export const WebinarsPreviousWebinarNew = () => import('../..\\components\\webinars\\PreviousWebinarNew.vue' /* webpackChunkName: "components/webinars-previous-webinar-new" */).then(c => wrapFunctional(c.default || c))
export const WebinarsWebinarNew = () => import('../..\\components\\webinars\\WebinarNew.vue' /* webpackChunkName: "components/webinars-webinar-new" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
